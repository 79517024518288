import { Locale } from '@fixter/i18n';

/**
 * Keep track of all Kameleoon experiments.
 * Remember to remove related code when the experiment is done.
 */
export enum ActiveExperiments {
  MobileTimetableOnlyUK = 'MOBILE_TIMETABLE_ONLY_UK',
  MobileTimetableOnlyFR = 'MOBILE_TIMETABLE_ONLY_FR',

  DiscountBannerPCT = 'DISCOUNT_BANNER_PCT_V2',

  EWOShockPricingUK = 'EWO_SHOCK_PRICING_UK',
  EWOShockPricingFR = 'EWO_SHOCK_PRICING_FR',

  ServicingQuotesPriceMatchPromiseUK = 'SERVICING_QUOTES_PRICE_MATCH_PROMISE_UK',

  KlarnaFakeDoorInCheckoutFlowUK = 'KLARNA_FAKE_DOOR_IN_CHECKOUT_FLOW_UK',

  BrandCampaignUK = 'BRAND_CAMPAIGN_UK',

  /**
   * Active campaign popup form.
   */
  ACForm10Off = 'AC_FORM_10_OFF',

  RevisionConstructeurFR = 'REVISION_CONSTRUCTEUR_FR',

  CustomerCndMapViewUK = 'CUSTOMER_CND_MAP_VIEW_UK',
  CustomerCndMapViewFR = 'CUSTOMER_CND_MAP_VIEW_FR',

  AnimatedHomepageHeroUK = 'ANIMATED_HOMEPAGE_HERO_UK',
  AnimatedHomepageHeroFR = 'ANIMATED_HOMEPAGE_HERO_FR',

  liquidCrossSellFlowUK = 'LIQUID_CROSS_SELL_FLOW_UK',
  liquidCrossSellFlowFR = 'LIQUID_CROSS_SELL_FLOW_FR',

  SelectProductCtaUK = 'SELECT_PRODUCT_CTA_UK',
  SelectProductCtaFR = 'SELECT_PRODUCT_CTA_FR',
}

/**
 * AB test: SelectProductCtaUK & SelectProductCtaFR
 * @note hardcoding the translations for now as it's an AB test
 */
export const SELECT_PRODUCT_CTA_DICTIONARY = {
  [Locale.EN_GB]: {
    addToBasketShortText: 'Select',
    addToBasketText: 'Select',
    addedToBasketText: 'Selected',
  },
  [Locale.FR_FR]: {
    addToBasketShortText: 'Sélectionner',
    addToBasketText: 'Sélectionner',
    addedToBasketText: 'Sélectionné',
  },
};
